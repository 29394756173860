import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { getPrice, ComposableLineItem, getDiscount } from "utils/cart";
import useProductOptions from "hooks/useProductOptions";
import { useLocale } from "hooks/useLocale";
import formatMoney from "utils/common/formatMoney";

interface Props {
  item: ComposableLineItem;
}

export default function DefaultSummaryLine({ item }: Props) {
  const productOptions = useProductOptions();
  const { getLocalizedValue } = useLocale();
  const { base } = item;
  const baseVariant = item.base.variant!;
  const title = getLocalizedValue(base.allLocaleValues.titles);
  const bundlePrice = getPrice(item);
  const bundleDiscount = getDiscount(item);
  const selectedOptions = baseVariant.selectedOptions
    ? baseVariant.selectedOptions.filter(
        option =>
          option.name !== "Title" &&
          option.value !== "Default Title" &&
          option.name !== "_bundleId"
      )
    : [];
  return (
    <Container>
      <SummaryLineInfo>
        <SummaryProductTitle>
          {base.quantity} x {title}
        </SummaryProductTitle>
        {selectedOptions.map(option => {
          const localizedOption = productOptions[option.name];
          const localizedValue = localizedOption.values.find(
            value => value.shopifyValue === option.value
          );
          return (
            <OptionContainer key={`${option.name}/${option.value}`}>
              <OptionName>{localizedOption.optionName}:</OptionName>
              <OptionValue>
                {localizedValue ? localizedValue.value : option.value}
              </OptionValue>
            </OptionContainer>
          );
        })}
      </SummaryLineInfo>
      <SummaryLinePrice>
        {formatMoney((bundlePrice - bundleDiscount) * 100, "€{{amount}}")}
      </SummaryLinePrice>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 10px 0;
`;

const SummaryProductTitle = styled.div`
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.3em;
  color: #333;

  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  color: #706f6f;
  font-size: 12px;
  letter-spacing: 0.06em;
`;

const OptionName = styled.span`
  padding-right: 5px;
`;

const OptionValue = styled.span`
  letter-spacing: 0.06em;
  font-size: 12px;
  line-height: 1.3em;
`;

const SummaryLineInfo = styled.div`
  width: 100%;
`;

const SummaryLinePrice = styled.div`
  padding-left: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3em;
  letter-spacing: 0.06em;
`;
