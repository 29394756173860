import React from "react";
import styled from "styled-components";
import { LineItem } from "hooks/useCart";
import { mergeBundleLineItems, getBundleId } from "utils/cart";
import DefaultLineItem from "./DefaultLineItem";
import ComposableLineItem from "./ComposableLineItem";

interface Props {
  items: LineItem[];
  onRemove: (lineItemIds: string | string[]) => void;
  onChangeQuantity: (lineItemId: string, quantity: number) => void;
}

function LineItems({ items, onRemove, onChangeQuantity }: Props) {
  const cartLineItems = mergeBundleLineItems(items);
  return (
    <Container>
      {cartLineItems.map(cartLineItem => {
        switch (cartLineItem.type) {
          case "composable":
            const bundleId = getBundleId(cartLineItem.base);
            return (
              <ComposableLineItem
                key={bundleId}
                item={cartLineItem}
                onRemove={() =>
                  onRemove(
                    [cartLineItem.base.id].concat(
                      cartLineItem.instants.map(instant => instant.id)
                    )
                  )
                }
              />
            );
          case "default":
            return (
              <DefaultLineItem
                key={cartLineItem.item.id}
                item={cartLineItem.item}
                onChangeQuantity={newQuantity =>
                  onChangeQuantity(cartLineItem.item.id, newQuantity)
                }
                onRemove={() => onRemove(cartLineItem.item.id)}
              />
            );
          default:
            return assertNever(cartLineItem);
        }
      })}
    </Container>
  );
}

function assertNever(x: never) {
  console.error(`Unexpected cart line item`, x);
}

const Container = styled.div`
  overflow: scroll;
`;

export default LineItems;
