import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Wrapper from "components/Wrapper";
import { useCart } from "hooks/useCart";
import { useTranslation } from "hooks/useTranslation";
import { FormattedMessage } from "react-intl";
import { getPersistedLocale } from "utils/persistence/locale";
import { FieldLocale } from "utils/common/locale";
import ButtonLink from "components/ButtonLink";
import LineItems from "./LineItems";
import Summary from "./Summary";
import SEO from "components/SEO";
import { useLocale } from "hooks/useLocale";

interface Props {
  pageContext: {
    defaultLocale: string;
    allPathLocales: FieldLocale<string>[];
    pagePath: string;
  };
}

const CartPage: React.SFC<Props> = ({ pageContext }) => {
  const { defaultLocale, pagePath } = pageContext;

  const [locale, setLocale] = useState<string>();

  async function loadLocale() {
    const locale = await getPersistedLocale();
    setLocale(locale || defaultLocale);
  }

  // Effects
  useEffect(() => {
    loadLocale();
  }, []);

  return locale ? <CartTemplate pagePath={pagePath} /> : null;
};

function CartTemplate({ pagePath }: { pagePath: string }) {
  const { formatMessage } = useTranslation();
  const { locale, defaultLocale } = useLocale();
  const { cart, removeLineItem, changeQuantity } = useCart();
  let checkoutUrl: string | null = null;
  if (cart && cart.webUrl) {
    checkoutUrl = `${cart.webUrl}${
      cart.webUrl.includes("?") ? "&" : "?"
    }locale=${locale}`;
  }
  const lineItems = cart
    ? cart.lineItems.filter(item => item.state !== "removing")
    : [];
  return cart ? (
    <Container>
      {lineItems.length > 0 ? (
        <Wrapper>
          <SEO
            title={formatMessage({ id: "cart.title" })}
            link={[
              { rel: "canonical", href: "https://ruedesmille.com" + pagePath }
            ]}
          />
          <Title>
            {formatMessage({
              id: "cart.title",
              defaultMessage: "Shopping bag"
            })}
          </Title>
          <ContentContainer>
            <ProductsList>
              <Grid>
                <Head>
                  <Label>
                    <FormattedMessage id="cartPage.product" />
                  </Label>
                  <Label>
                    <FormattedMessage id="cartPage.price" />
                  </Label>
                  <Label>
                    <FormattedMessage id="cartPage.quantity" />
                  </Label>
                  <Label>
                    <FormattedMessage id="cartPage.total" />
                  </Label>
                </Head>
                <Body>
                  <LineItems
                    items={lineItems}
                    onChangeQuantity={changeQuantity}
                    onRemove={removeLineItem}
                  />
                </Body>
              </Grid>
            </ProductsList>
            {checkoutUrl && (
              <Summary items={lineItems} checkoutUrl={checkoutUrl} />
            )}
          </ContentContainer>
        </Wrapper>
      ) : (
        <EmptyCartContainer>
          <EmptyCartContent>
            <Title>
              {formatMessage({
                id: "cart.title",
                defaultMessage: "Shopping bag"
              })}
            </Title>
            <EmptyCartMessage>
              {formatMessage({
                id: "shopping-bag.empty-cart-message",
                defaultMessage: "Your shopping bag is currently empty"
              })}
            </EmptyCartMessage>
            <ButtonLink to={locale === defaultLocale ? `/` : `/${locale}/`}>
              {formatMessage({
                id: "shopping-bag.empty-cart-call-to-action",
                defaultMessage: "Shop now"
              })}
            </ButtonLink>
          </EmptyCartContent>
        </EmptyCartContainer>
      )}
    </Container>
  ) : null;
}

const EmptyCartContainer = styled.div`
  display: flex;
  padding: 125px 30px;
  justify-content: center;
  align-items: center;
  ${ButtonLink} {
    margin: 20px 0;
  }
`;

const EmptyCartContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const EmptyCartMessage = styled.div``;

const Container = styled.div`
  padding-top: 40px;
`;

const ContentContainer = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 30px;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 1000px) {
    text-align: center;
  }
`;

const ProductsList = styled.div`
  display: flex;
  flex: 1;
  margin-right: 40px;
  @media (max-width: 1240px) {
    margin-right: 20px;
  }
  @media (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

const Grid = styled.div`
  margin: 0;
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.2em;
`;

const Label = styled.div``;

const Head = styled.div`
  background-color: #f8f8f8;
  color: #706f6f;
  height: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  ${Label} {
    padding: 0 15px;
    &:nth-of-type(1) {
      flex: 0 0 55%;
    }
    &:nth-of-type(2) {
      flex: 0 0 15%;
    }
    &:nth-of-type(3) {
      flex: 0 0 15%;
    }
    &:nth-of-type(4) {
      flex: 0 0 15%;
    }
  }
  @media (max-width: 650px) {
    display: none;
  }
`;

const Body = styled.div``;

export default CartPage;
