import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { LineItem as ILineItem } from "hooks/useCart";
import { useLocale } from "hooks/useLocale";
import useProductOptions from "hooks/useProductOptions";
import formatMoney from "utils/common/formatMoney";
import QuantityInput from "components/cart/QuantityInput";
import Close from "components/icons/Cross";

interface Props {
  item: ILineItem;
  onChangeQuantity: (newQuantity: number) => void;
  onRemove: () => void;
}

export default function LineItem({ item, onChangeQuantity, onRemove }: Props) {
  const productOptions = useProductOptions();
  const { getLocalizedValue } = useLocale();
  const title = getLocalizedValue(item.allLocaleValues.titles);
  const path = getLocalizedValue(item.allLocaleValues.paths) || "#";
  const discount =
    ((!!item.discountAllocations &&
      Number(
        item.discountAllocations.reduce(
          (acc, discount) => acc + Number(discount.allocatedAmount.amount),
          0
        )
      )) ||
      0) * 100;
  const price = parseFloat(item.variant!.price) * 100;
  const compareAtPrice = item.variant!.compareAtPrice
    ? parseFloat(item.variant!.compareAtPrice) * 100
    : null;
  const isDiscounted =
    (!!compareAtPrice && compareAtPrice > 0 && compareAtPrice !== price) ||
    discount > 0;
  const selectedOptions = item.variant!.selectedOptions
    ? item.variant!.selectedOptions.filter(
        (option) => option.name !== "Title" && option.value !== "Default Title"
      )
    : [];

  return (
    <Product>
      <Item>
        <ImageContainer>
          <ImageLink to={path}>
            <Image
              src={item.variant!.image ? item.variant!.image.originalSrc : ""}
              alt={
                (item.variant!.image ? item.variant!.image.altText : title) ||
                ""
              }
            />
          </ImageLink>
        </ImageContainer>

        <Details>
          <ProductLink to={path}>
            <ProductTitle>{title}</ProductTitle>
          </ProductLink>
          {selectedOptions.map((option) => {
            const localizedOption = productOptions[option.name];
            const localizedValue = localizedOption.values.find(
              (value) => value.shopifyValue === option.value
            );
            return (
              <OptionContainer key={`${option.name}/${option.value}`}>
                <OptionName>{localizedOption.optionName}:</OptionName>
                <OptionValue>
                  {localizedValue ? localizedValue.value : option.value}
                </OptionValue>
              </OptionContainer>
            );
          })}
          {item.customAttributes &&
            item.customAttributes.map((elem: any, index: number) => (
              <OptionContainer key={index}>
                <OptionName>
                  {elem.key === "engraving_text" && (
                    <FormattedMessage id="engravingSelector.text" />
                  )}
                  {elem.key === "engraving_font" && (
                    <FormattedMessage id="engravingSelector.font" />
                  )}
                  {elem.key.startsWith("text_") && (
                    <>
                      <FormattedMessage id="label.text" />
                      {item.customAttributes.length > 1 ? ` ${index + 1}` : ``}
                    </>
                  )}
                  :
                </OptionName>
                <OptionValue>{elem.value}</OptionValue>
              </OptionContainer>
            ))}
        </Details>
      </Item>
      <Item>
        <Pricing>
          {isDiscounted && (
            <CompareAtPrice>
              {formatMoney(compareAtPrice || price, "€{{amount}}")}
            </CompareAtPrice>
          )}
          <Price isDiscounted={isDiscounted}>
            {formatMoney(price - discount, "€{{amount}}")}
          </Price>
        </Pricing>
      </Item>
      <Item>
        <QuantityInput
          className="itemQuantity"
          quantity={item.quantity}
          isChangingQuantity={item.state === "changing_quantity"}
          onChangeQuantity={onChangeQuantity}
        />
      </Item>
      <Item>
        <Pricing>
          {isDiscounted && (
            <CompareAtPrice>
              {formatMoney(
                (compareAtPrice || price) * item.quantity,
                "€{{amount}}"
              )}
            </CompareAtPrice>
          )}
          <Price isDiscounted={isDiscounted}>
            {formatMoney(price * item.quantity - discount, "€{{amount}}")}
          </Price>
        </Pricing>
      </Item>

      <RemoveProduct onClick={onRemove}>
        <RemoveIcon />
      </RemoveProduct>
    </Product>
  );
}

const Item = styled.div``;

const Product = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  position: relative;
  padding: 15px 0;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: start;

    &:first-child {
      border-top: 1px solid #ddd;
    }
  }
  ${Item} {
    padding: 0 15px;
    letter-spacing: 0.06em;
    &:nth-of-type(1) {
      flex: 0 0 55%;
      display: flex;
      align-items: center;
    }
    &:nth-of-type(2) {
      flex: 0 0 15%;
    }
    &:nth-of-type(3) {
      flex: 0 0 15%;
    }
    &:nth-of-type(4) {
      flex: 0 0 15%;
    }
    @media (max-width: 650px) {
      width: 100%;

      &:nth-of-type(n) {
        flex: 0 0 100%;
      }
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        text-align: right;
      }
      &:nth-of-type(2) {
        color: #706f6f;
      }
      &:nth-of-type(4) {
        font-size: 18px;
        padding-bottom: 10px;
        font-weight: 600;
      }

      .itemQuantity {
        justify-content: flex-end;
        padding: 10px 0;
      }
    }
    @media (max-width: 500px) {
      padding: 0;
    }
  }
`;

const ProductLink = styled(Link)`
  font-weight: 500;
  letter-spacing: 0.04em;
  padding-bottom: 5px;
  text-decoration: none;
  color: #333;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

const ImageContainer = styled.div`
  width: 120px;
  @media (max-width: 400px) {
    width: 100px;
  }
`;

const ImageLink = styled(Link)`
  display: block;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  display: block;
`;

const ProductTitle = styled.div`
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.3em;
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
  min-width: calc(100% - 120px);
  padding-left: 15px;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 400px) {
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);
  }
`;

const OptionName = styled.span`
  padding-right: 5px;
`;

const OptionValue = styled.span``;

const OptionContainer = styled.div`
  display: flex;
  align-items: start;
  font-weight: 600;
  color: #706f6f;
  font-size: 12px;
  letter-spacing: 0.06em;
`;

const Pricing = styled.div`
  display: block;
  @media (max-width: 650px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const Price = styled.div<{ isDiscounted: boolean }>`
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
`;

const CompareAtPrice = styled.div`
  text-decoration: line-through;
  padding-right: 5px;
  font-size: 14px;
  color: #333;
  text-align: left;
`;

const RemoveProduct = styled.div`
  position: absolute;
  top: 6px;
  right: 4px;
  color: ${({ theme }) => theme.colors.text};
  padding: 4px;
  opacity: 0.6;
  transition: 0.3s all;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const RemoveIcon = styled(Close)`
  height: 18px;
  width: 18px;
  display: block;
`;
