import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { LineItem } from "hooks/useCart";
import useProductOptions from "hooks/useProductOptions";
import { useLocale } from "hooks/useLocale";
import formatMoney from "utils/common/formatMoney";

interface Props {
  item: LineItem;
}

export default function DefaultSummaryLine({ item }: Props) {
  const productOptions = useProductOptions();
  const { getLocalizedValue } = useLocale();
  const title = getLocalizedValue(item.allLocaleValues.titles);
  const price = parseFloat(item.variant!.price) * 100;
  const discount =
    ((!!item.discountAllocations &&
      Number(
        item.discountAllocations.reduce(
          (acc, discount) => acc + Number(discount.allocatedAmount.amount),
          0
        )
      )) ||
      0) * 100;
  const selectedOptions = item.variant!.selectedOptions
    ? item.variant!.selectedOptions.filter(
        (option) => option.name !== "Title" && option.value !== "Default Title"
      )
    : [];
  return (
    <Container>
      <SummaryLineInfo>
        <SummaryProductTitle>
          {item.quantity} x {title}
        </SummaryProductTitle>
        {selectedOptions.map((option) => {
          const localizedOption = productOptions[option.name];
          const localizedValue = localizedOption.values.find(
            (value) => value.shopifyValue === option.value
          );
          return (
            <OptionContainer key={`${option.name}/${option.value}`}>
              <OptionName>{localizedOption.optionName}:</OptionName>
              <OptionValue>
                {localizedValue ? localizedValue.value : option.value}
              </OptionValue>
            </OptionContainer>
          );
        })}
        {item.customAttributes &&
          item.customAttributes.map((elem, index) => (
            <OptionContainer key={index}>
              <OptionName>
                {elem.key === "engraving_text" && (
                  <FormattedMessage id="engravingSelector.text" />
                )}
                {elem.key === "engraving_font" && (
                  <FormattedMessage id="engravingSelector.font" />
                )}
                {elem.key.startsWith("text_") && (
                  <>
                    <FormattedMessage id="label.text" />
                    {item.customAttributes.length > 1 ? ` ${index + 1}` : ``}
                  </>
                )}
                :
              </OptionName>
              <OptionValue>{elem.value}</OptionValue>
            </OptionContainer>
          ))}
      </SummaryLineInfo>
      <SummaryLinePrice>
        {formatMoney((price - discount) * item.quantity, "€{{amount}}")}
      </SummaryLinePrice>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 10px 0;
`;

const SummaryProductTitle = styled.div`
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.3em;
  color: #333;

  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  color: #706f6f;
  font-size: 12px;
  letter-spacing: 0.06em;
`;

const OptionName = styled.span`
  padding-right: 5px;
`;

const OptionValue = styled.span`
  letter-spacing: 0.06em;
  font-size: 12px;
  line-height: 1.3em;
`;

const SummaryLineInfo = styled.div`
  width: 100%;
`;

const SummaryLinePrice = styled.div`
  padding-left: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3em;
  letter-spacing: 0.06em;
`;
