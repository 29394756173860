import InputText from "components/InputText";
import { useTranslation } from "hooks/useTranslation";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  onChange: (email: string) => void;
  error: boolean;
}

const SplitShippingEmail: React.SFC<Props> = ({ onChange, error }) => {
  const { formatMessage } = useTranslation();

  return (
    <Container>
      <Label>{formatMessage({ id: "label.email" })}</Label>
      <Description>
        {formatMessage({ id: "splitShipping.emailDescription" })}
      </Description>
      <InputText
        size="small"
        type="text"
        hasError={error}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Label = styled.div`
  font-size: 14px;
`;

const Description = styled.div`
  font-size: 11px;
  color: #706f6f;
  padding-bottom: 7px;
  padding-top: 5px;
`;

export default SplitShippingEmail;
