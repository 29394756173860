import InputText from "components/InputText";
import Select from "components/Select";
import { useTranslation } from "hooks/useTranslation";
import { MailingAddressInput } from "hooks/useUser";
import React, { ChangeEvent, useMemo } from "react";
import styled from "styled-components";
import countries from "utils/data/country.json";

interface Props {
  address: MailingAddressInput;
  onChange: (address: MailingAddressInput) => void;
  fieldsWithError: string[];
}

interface Province {
  code: string;
  name: string;
}

const SplitShippingAddress: React.SFC<Props> = ({
  address,
  fieldsWithError,
  onChange
}) => {
  const { formatMessage } = useTranslation();

  const _extractProvinces = (country: string): Province[] => {
    const foundCountry = countries.find(
      c => c.code === country || c.name === country
    );
    return foundCountry ? foundCountry.provinces : [];
  };

  // Memoized methods
  const provinces = useMemo(
    () => (address.country ? _extractProvinces(address.country) : []),
    [address.country]
  );

  return (
    <Container>
      <div>
        <Label>
          {formatMessage({
            id: "label.firstName",
            defaultMessage: "First name"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("firstName")}
          value={address.firstName || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, firstName: e.target.value })
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.lastName",
            defaultMessage: "Last name"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("lastName")}
          value={address.lastName || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, lastName: e.target.value })
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.company",
            defaultMessage: "Company"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("company")}
          value={address.company || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, company: e.target.value })
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.address1",
            defaultMessage: "Address 1"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("address1")}
          value={address.address1 || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, address1: e.target.value })
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.address2",
            defaultMessage: "Address 2"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("address2")}
          value={address.address2 || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, address2: e.target.value })
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.city",
            defaultMessage: "Ciry"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("city")}
          value={address.city || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, city: e.target.value })
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.country",
            defaultMessage: "Country"
          })}
        </Label>
        <Select
          size="small"
          placeholder=""
          hasError={fieldsWithError.includes("country")}
          value="IT"
          useSystemSelect={true}
          options={countries}
          display={value => value.name}
          disabled={true}
          valueChange={value =>
            onChange({
              ...address,
              country: value.code,
              province:
                value.provinces &&
                value.provinces.length > 0 &&
                value.provinces[0].code
            })
          }
          compare={(value, option) =>
            value === option.code || value === option.name
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.province",
            defaultMessage: "State / Province"
          })}
        </Label>
        <Select
          size="small"
          placeholder=""
          value={address.province}
          hasError={fieldsWithError.includes("province")}
          disabled={provinces.length === 0}
          options={provinces}
          useSystemSelect={true}
          display={value => value.name}
          valueChange={value => onChange({ ...address, province: value.code })}
          compare={(value, option) =>
            value === option.code || value === option.name
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.zip",
            defaultMessage: "Postal / Zip Code"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("zip")}
          value={address.zip || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, zip: e.target.value })
          }
        />
      </div>
      <div>
        <Label>
          {formatMessage({
            id: "label.phone",
            defaultMessage: "Phone"
          })}
        </Label>
        <InputText
          size="small"
          type="text"
          hasError={fieldsWithError.includes("phone")}
          value={address.phone || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange({ ...address, phone: e.target.value })
          }
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Label = styled.div`
  font-size: 14px;
`;

export default SplitShippingAddress;
