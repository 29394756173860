import ButtonLink from "components/ButtonLink";
import { LineItem, useCart } from "hooks/useCart";
import { FieldLocale, useLocale, useLocalizedValue } from "hooks/useLocale";
import { useTranslation } from "hooks/useTranslation";
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import formatMoney from "utils/common/formatMoney";
import SummaryLineItems from "./SummaryLineItems";
import { MailingAddressInput } from "hooks/useUser";
import { FormattedMessage } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import SelectProductToShip from "./SelectProductToShip";
import SplitShippingEmail from "./SplitShippingEmail";
import SplitShippingAddress from "./SplitShippingAddress";
import SplitShippingTextArea from "./SplitShippingTextArea";
import InputCheckbox from "components/InputCheckbox";
import Button from "components/Button";
import Link from "components/Link";
import useProductOptions from "hooks/useProductOptions";
import Select from "components/Select";
import InputTextArea from "components/InputTextArea";
import { getAllProductPathLocales } from "utils/common/navigation";
import { convertStorefrontIdToShopifyId } from "utils/product";
import { getFirstMediaImage } from "utils/image";

interface Props {
  items: LineItem[];
  checkoutUrl: string;
}

interface StaticQuery {
  datoCmsGeneral: {
    linkPrivacy: {
      _allHandleLocales: FieldLocale<string>[];
    };
  };
  datoCmsGiftPackage: {
    _allCheckboxTextLocales: {
      locale: string;
      value: string;
    }[];
    _allPlaceholderTextareaLocales: {
      locale: string;
      value: string;
    }[];
  };
  datoCmsSplitShippingPromoAtCheckout: {
    shopifyHandle: string;
    bannerImage: {
      url: string;
    };
    _allAvailablePromoTextLocales: {
      locale: string;
      valueNode: {
        childMarkdownRemark: {
          html: string;
        };
      };
    }[];
    _allObtainedPromoTextLocales: {
      locale: string;
      valueNode: {
        childMarkdownRemark: {
          html: string;
        };
      };
    }[];
  };
}

export default function Summary({ items, checkoutUrl }: Props) {
  const { locale, defaultLocale } = useLocale();
  const { formatMessage } = useTranslation();
  const { getLocalizedValue } = useLocale();
  const productOptions = useProductOptions();
  const {
    cart,
    addToCart,
    giftAtCheckout,
    selectedGiftVariantId,
    totalPriceForGiftAttribution = 0,
    changeGiftVariant,
    splitShippingIds,
    addNote,
    cartUpsell,
  } = useCart();

  const selectedGiftVariant =
    giftAtCheckout &&
    selectedGiftVariantId &&
    giftAtCheckout.variants.find(
      (v) => v.storefrontId === selectedGiftVariantId
    );

  const totalPrice = items.reduce(
    (sum, item) => sum + parseFloat(item.variant!.price) * item.quantity,
    0
  );
  const totalDiscount = items.reduce(
    (discount, item) =>
      discount +
      (item.discountAllocations && item.discountAllocations.length > 0
        ? item.discountAllocations.reduce(
            (acc, d) => acc + Number(d.allocatedAmount.amount),
            0
          )
        : 0),
    0
  );
  const {
    datoCmsSplitShippingPromoAtCheckout: splitShippingPromo,
    datoCmsGeneral,
    datoCmsGiftPackage,
  } = useStaticQuery<StaticQuery>(staticQuery);

  const [showShipping, setShowShipping] = useState(false);
  const [shipOnlyToMe, setShipOnlyToMe] = useState(false);
  const [productToShip, setProductToShip] = useState<string>();
  const [shippingEmail, setShippingEmail] = useState<string>();
  const [shippingEmailError, setShippingEmailError] = useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<MailingAddressInput>({
    country: "IT",
  });
  const [shippingFieldsWithError, setShippingFieldsWithError] = useState<
    string[]
  >([]);
  const [message, setMessage] = useState<string>();
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [giftPackage, setGiftPackage] = useState(false);
  const [giftPackageNote, setGiftPackageNote] = useState("");

  const linksPrivacy = datoCmsGeneral.linkPrivacy._allHandleLocales;
  const localizedUrl = linksPrivacy.find(
    (p: FieldLocale) => p.locale === locale
  )!.value;
  const privacyLinkLocalized =
    locale === defaultLocale
      ? `/${localizedUrl}/`
      : `/${locale}/${localizedUrl}`;

  useEffect(() => {
    setShowShipping(false);
  }, [items]);

  let enableSplitShippingPromo =
    items.reduce((acc: number, item) => {
      return splitShippingIds.includes(item.variant!.id)
        ? acc + item.quantity
        : acc;
    }, 0) > 1;

  const handleDefaultSubmit = async () => {
    setIsLoading(true);
    if (giftPackage) {
      await addNote(`Opzione regalo\n\nTesto:\n${giftPackageNote}`);
    } else {
      await addNote("");
    }
    setIsLoading(false);
    sendCheckoutEvent();
    location.href = checkoutUrl!;
  };

  const handleSplitShippingSubmit = async () => {
    setIsLoading(true);
    if (!shipOnlyToMe) {
      const emailError =
        !shippingEmail ||
        !shippingEmail.match(
          /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
        );
      const addressErrors = checkAddressValidity(shippingAddress);
      setShippingEmailError(emailError);
      setShippingFieldsWithError(addressErrors);
      setPrivacyError(!checkedPrivacy);
      if (emailError || addressErrors.length > 0 || !checkedPrivacy) {
        setIsLoading(false);
        return;
      }
      const noteString = `PROMOZIONE\n\nRiferimento:\n${productToShip}\n\nEmail:\n${shippingEmail}\n\nIndirizzo:\nNome: ${
        shippingAddress.firstName
      }\nCognome: ${shippingAddress.lastName}\nRagione sociale: ${
        shippingAddress.company || ""
      }\nIndirizzo 1: ${shippingAddress.address1}\nIndirizzo 2: ${
        shippingAddress.address2 || ""
      }\nStato: ${shippingAddress.country}\nCittà: ${
        shippingAddress.city
      }\nProvincia: ${shippingAddress.province}\nCap: ${
        shippingAddress.zip || ""
      }\nTelefono: ${shippingAddress.phone}\n\nMessaggio regalo:\n${message}`;
      await addNote(noteString);
      setIsLoading(false);
    } else {
      await addNote("PROMOZIONE");
      setIsLoading(false);
    }
    sendCheckoutEvent();
    location.href = checkoutUrl!;
  };

  const sendCheckoutEvent = () => {
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "checkout",
      eventCategory: "Ecommerce",
      eventAction: "Initiate Checkout",
      ecommerce: {
        checkout: {
          products: cart?.lineItems.map((lineItem) => {
            const productId = lineItem.variant?.product.id || "";
            const variantId = lineItem.variant?.id || "";
            return {
              id: lineItem.variant?.sku,
              name:
                (lineItem.variant?.product.variants.edges?.length || 0) > 1
                  ? `${lineItem.variant?.product.title} - ${lineItem.variant?.title}`
                  : lineItem.variant?.product.title,
              variant: lineItem.variant?.title,
              price: lineItem.variant?.price,
              quantity: lineItem.quantity,
              product_id: productId,
              variant_id: variantId,
            };
          }),
        },
      },
    });
  };

  const renderCartUpsell = () => {
    const title = useLocalizedValue(cartUpsell.datoCmsProduct._allTitleLocales);
    const category = useLocalizedValue(
      cartUpsell.datoCmsProduct.category._allHandleLocales
    );
    const handle = useLocalizedValue(
      cartUpsell.datoCmsProduct._allHandleLocales
    );
    const path = `${
      locale === defaultLocale ? `` : `/${locale}`
    }/${category}/${handle}/`;

    const price = Number(cartUpsell.shopifyProduct.variants[0].price);
    const compareAtPrice = Number(
      cartUpsell.shopifyProduct.variants[0].compareAtPrice
    );

    const isDiscounted =
      !!compareAtPrice && compareAtPrice > 0 && compareAtPrice !== price;

    return cartUpsell &&
      cartUpsell.shopifyProduct.availableForSale &&
      cart &&
      !cart.lineItems.find(
        (item) =>
          item.variant!.id ===
          cartUpsell.shopifyProduct.variants[0].storefrontId
      ) ? (
      <CartUpsellWrapper>
        <CartUpsellContainer>
          <CartUpsellHeading>
            {getLocalizedValue(cartUpsell._allTitleLocales)}
          </CartUpsellHeading>
          <CartUpsellImageContainer>
            <CartUpsellImageLink to={path}>
              <CartUpsellImage
                src={
                  cartUpsell &&
                  getFirstMediaImage(cartUpsell.shopifyProduct.media)
                    ?.originalSrc
                }
              />
            </CartUpsellImageLink>
          </CartUpsellImageContainer>

          <CartUpsellDetails>
            <CartUpsellItemLink to={path}>
              <CartUpsellTitle>{title}</CartUpsellTitle>
            </CartUpsellItemLink>
            <CartUpsellTotalContainer>
              <CartUpsellPrice>
                {isDiscounted && (
                  <CartUpsellOriginalPrice>
                    {formatMoney(compareAtPrice * 100, "€{{amount}}")}
                  </CartUpsellOriginalPrice>
                )}
                <CartUpsellTotal isDiscounted={isDiscounted}>
                  {formatMoney(
                    Number(cartUpsell.shopifyProduct.variants[0].price) * 100,
                    "€{{amount}}"
                  )}
                </CartUpsellTotal>
              </CartUpsellPrice>
            </CartUpsellTotalContainer>
            <CartUpsellAddToCartContainer>
              <CartUpsellAddToCart
                size="slim"
                onClick={() => {
                  const selectedVariant = cartUpsell.shopifyProduct.variants[0];
                  addToCart({
                    variant: {
                      id: selectedVariant.storefrontId,
                      price: selectedVariant.price!,
                      compareAtPrice: selectedVariant.compareAtPrice!,
                      image: selectedVariant.image
                        ? {
                            altText:
                              getLocalizedValue(
                                cartUpsell.datoCmsProduct._allTitleLocales
                              ) || "",
                            originalSrc: selectedVariant.image
                              .originalSrc as string,
                          }
                        : undefined,
                    },
                    quantity: 1,
                    customAttributes: [],
                    allLocaleValues: {
                      titles: cartUpsell.datoCmsProduct._allTitleLocales as any,
                      paths: getAllProductPathLocales(
                        cartUpsell.datoCmsProduct as any,
                        defaultLocale
                      ),
                    },
                  });
                }}
              >
                ADD
              </CartUpsellAddToCart>
            </CartUpsellAddToCartContainer>
          </CartUpsellDetails>
        </CartUpsellContainer>
      </CartUpsellWrapper>
    ) : null;
  };

  return (
    <SummaryContainer>
      <OrderSummary>
        {!showShipping ? (
          <>
            <SummaryHeader>
              {formatMessage({
                id: "shopping-bag.summary.title",
                defaultMessage: "Order summary",
              })}
            </SummaryHeader>
            <SummaryContent>
              <SummaryLineItems items={items} />
            </SummaryContent>
            {cartUpsell && renderCartUpsell()}
            {giftAtCheckout && giftAtCheckout.availableForSale ? (
              <GiftContainer>
                <GiftDetail>
                  <GiftImage
                    src={
                      (selectedGiftVariant &&
                        selectedGiftVariant.image &&
                        selectedGiftVariant.image.originalSrc) ||
                      giftAtCheckout.imageSrc
                    }
                  />
                  <GiftDescriptionWrapper>
                    <GiftDescription
                      dangerouslySetInnerHTML={{
                        __html:
                          totalPriceForGiftAttribution -
                            giftAtCheckout.minimumAmountSpent <
                          0
                            ? getLocalizedValue(
                                giftAtCheckout.allGiftNotEarnedTextNodeLocale
                              )!.childMarkdownRemark.html.replace(
                                "{price}",
                                `${
                                  giftAtCheckout.minimumAmountSpent -
                                  totalPriceForGiftAttribution
                                }`
                              )
                            : getLocalizedValue(
                                giftAtCheckout.allGiftEarnedTextNodeLocale
                              )!.childMarkdownRemark.html,
                      }}
                    />
                    {totalPriceForGiftAttribution -
                      giftAtCheckout.minimumAmountSpent >=
                      0 &&
                      giftAtCheckout.variants.length > 1 && (
                        <GiftVariantSelect
                          size="small"
                          placeholder=""
                          value={selectedGiftVariantId}
                          useSystemSelect
                          options={giftAtCheckout.variants.filter(
                            (v) => v.availableForSale
                          )}
                          display={(value) =>
                            value.selectedOptions.reduce(
                              (
                                acc: string,
                                option: { name: string; value: string }
                              ) => {
                                const localizedOption =
                                  productOptions[option.name];
                                const localizedValue =
                                  localizedOption.values.find(
                                    (value) =>
                                      value.shopifyValue === option.value
                                  );
                                return acc
                                  ? `acc / ${
                                      (localizedValue &&
                                        localizedValue.value) ||
                                      option.value
                                    }`
                                  : (localizedValue && localizedValue.value) ||
                                      option.value;
                              },
                              ""
                            )
                          }
                          valueChange={(value) =>
                            changeGiftVariant(value.storefrontId)
                          }
                          compare={(value, option) =>
                            value === option.shopifyId
                          }
                        />
                      )}
                  </GiftDescriptionWrapper>
                </GiftDetail>
                {totalPriceForGiftAttribution <
                  giftAtCheckout.minimumAmountSpent && (
                  <LoadingMaskContainer>
                    <LoadingMask>
                      <LoadingBar
                        percentage={
                          (100 * totalPriceForGiftAttribution) /
                          giftAtCheckout.minimumAmountSpent
                        }
                      />
                    </LoadingMask>
                    <TargetPrice>
                      €{giftAtCheckout.minimumAmountSpent}
                    </TargetPrice>
                  </LoadingMaskContainer>
                )}
              </GiftContainer>
            ) : null}
            {
              // TODO: force remove split shipping promo
              false && splitShippingPromo ? (
                <SplitShippingContainer>
                  <SplitShippingDetail>
                    <SplitShippingImage
                      src={splitShippingPromo.bannerImage.url}
                    />
                    <SplitShippingDescription
                      dangerouslySetInnerHTML={{
                        __html: !enableSplitShippingPromo
                          ? splitShippingPromo._allAvailablePromoTextLocales.find(
                              (o) => o.locale === locale
                            )!.valueNode.childMarkdownRemark.html
                          : splitShippingPromo._allObtainedPromoTextLocales.find(
                              (o) => o.locale === locale
                            )!.valueNode.childMarkdownRemark.html,
                      }}
                    />
                  </SplitShippingDetail>
                </SplitShippingContainer>
              ) : null
            }
            <TotalContainer>
              <OrderTotal>{formatMessage({ id: "label.total" })}</OrderTotal>
              <Total>
                {formatMoney((totalPrice - totalDiscount) * 100, "€{{amount}}")}
              </Total>
            </TotalContainer>
            {!enableSplitShippingPromo && checkoutUrl && (
              <>
                {datoCmsGiftPackage && (
                  <>
                    <StyledInputCheckbox
                      id="gift-package-cart-page"
                      isChecked={giftPackage}
                      onToggle={() => setGiftPackage((gp) => !gp)}
                      hasError={false}
                    >
                      <span>
                        {getLocalizedValue(
                          datoCmsGiftPackage._allCheckboxTextLocales
                        )}
                      </span>
                    </StyledInputCheckbox>
                    {giftPackage && (
                      <StyledInputTextArea
                        as="textarea"
                        placeholder={getLocalizedValue(
                          datoCmsGiftPackage._allPlaceholderTextareaLocales
                        )}
                        value={giftPackageNote}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setGiftPackageNote(e.target.value)
                        }
                      />
                    )}
                  </>
                )}
                <StyledButton
                  size="large"
                  loading={isLoading}
                  onClick={handleDefaultSubmit}
                >
                  checkout
                </StyledButton>
              </>
            )}
            {enableSplitShippingPromo && (
              <StyledButton size="large" onClick={() => setShowShipping(true)}>
                <FormattedMessage
                  id="cart.continue"
                  defaultMessage="Continue"
                />
              </StyledButton>
            )}
            <Note>
              {formatMessage({
                id: "shopping-bag.summary.note",
                defaultMessage: "You can apply discount code at checkout",
              })}
            </Note>
          </>
        ) : (
          <>
            <SummaryHeader>
              {formatMessage({ id: "splitShipping.title" })}
            </SummaryHeader>
            <InputCheckbox
              id="ship-only-to-me"
              isChecked={shipOnlyToMe}
              onToggle={() => setShipOnlyToMe(!shipOnlyToMe)}
              hasError={false}
            >
              <FormattedMessage id="splitShipping.abort" />
            </InputCheckbox>
            {!shipOnlyToMe && (
              <>
                <SelectProductToShip onChange={setProductToShip} />
                <SplitShippingEmail
                  onChange={setShippingEmail}
                  error={shippingEmailError}
                />
                <SplitShippingAddress
                  address={shippingAddress}
                  fieldsWithError={shippingFieldsWithError}
                  onChange={setShippingAddress}
                />
                <SplitShippingTextArea onChange={setMessage} />
                <Checkbox hasError={privacyError}>
                  <input
                    id="privacy-summary-cart"
                    type="checkbox"
                    checked={checkedPrivacy}
                    onChange={() => setCheckedPrivacy(!checkedPrivacy)}
                  />
                  <label htmlFor="privacy-summary-cart">
                    <FormattedMessage
                      id="cart.privacy"
                      values={{
                        privacyPolicy: (
                          <Link to={privacyLinkLocalized}>
                            <FormattedMessage id="label.cart.privacyLinkLabel" />
                          </Link>
                        ),
                      }}
                    />
                  </label>
                </Checkbox>
              </>
            )}
            <TotalContainer>
              <OrderTotal>{formatMessage({ id: "label.total" })}</OrderTotal>
              <Total>
                {formatMoney((totalPrice - totalDiscount) * 100, "€{{amount}}")}
              </Total>
            </TotalContainer>
            {!enableSplitShippingPromo && checkoutUrl && (
              <CheckoutButton to={checkoutUrl} size="large">
                {formatMessage({
                  id: "label.checkout",
                  defaultMessage: "Checkout",
                })}
              </CheckoutButton>
            )}
            {checkoutUrl && (
              <StyledButton
                size="large"
                loading={isLoading}
                onClick={handleSplitShippingSubmit}
              >
                checkout
              </StyledButton>
            )}
            <Note>
              {formatMessage({
                id: "shopping-bag.summary.note",
                defaultMessage: "You can apply discount code at checkout",
              })}
            </Note>
            <Back onClick={() => setShowShipping(false)}>
              <FormattedMessage id="cart.back" defaultMessage="Back" />
            </Back>
          </>
        )}
      </OrderSummary>
    </SummaryContainer>
  );
}

const checkAddressValidity = (address: MailingAddressInput): string[] => {
  const fieldsWithError = [];
  if (!address.company) {
    if (!address.firstName || !address.lastName) {
      fieldsWithError.push("firstName", "lastName", "company");
    }
  }
  if (!address.address1) {
    fieldsWithError.push("address1");
  }
  if (!address.city) {
    fieldsWithError.push("city");
  }
  if (!address.country) {
    fieldsWithError.push("country");
  }
  if (!address.phone) {
    fieldsWithError.push("phone");
  }
  return fieldsWithError;
};

const staticQuery = graphql`
  {
    datoCmsGeneral {
      linkPrivacy {
        _allHandleLocales {
          locale
          value
        }
      }
    }
    datoCmsGiftPackage {
      _allCheckboxTextLocales {
        locale
        value
      }
      _allPlaceholderTextareaLocales {
        locale
        value
      }
    }
    datoCmsSplitShippingPromoAtCheckout(locale: { eq: "it" }) {
      shopifyHandle
      bannerImage {
        url
      }
      _allAvailablePromoTextLocales {
        locale
        valueNode {
          childMarkdownRemark {
            html
          }
        }
      }
      _allObtainedPromoTextLocales {
        locale
        valueNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const SummaryContainer = styled.div`
  box-sizing: border-box;
  width: 420px;
  flex: 0 0 420px;
  color: #333;
  @media (max-width: 1240px) {
    width: 380px;
    flex: 0 0 380px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
`;

const OrderSummary = styled.div`
  background-color: #fff9e7;
  padding: 40px 30px;
  @media (max-width: 500px) {
    padding: 40px 20px;
  }
`;

const SummaryHeader = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  padding-bottom: 20px;
`;

const SummaryContent = styled.div``;

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 10px;
`;

const StyledButton = styled(Button)`
  margin: 20px 0;
`;

const OrderTotal = styled.span`
  font-weight: 600;
  letter-spacing: 0.12em;
  font-size: 14px;
  text-transform: uppercase;
  flex: 1;
  text-align: left;
  color: #333;
`;

const Total = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  letter-spacing: 0.12em;
`;

const GiftContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.background2};
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
  width: calc(100% + 60px);
  transform: translateX(-30px);
  margin-top: -1px;
  padding: 18px 20px 15px 20px;
`;

const GiftDetail = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
`;

const GiftImage = styled.img`
  margin-top: 5px;
  object-fit: cover;
  width: 50px;
  height: 50px;
`;

const GiftDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GiftDescription = styled.div`
  font-size: 14px;
  margin-left: 15px;
  color: #333;
  p {
    margin: 0;
  }
`;

const GiftVariantSelect = styled(Select)`
  margin-top: 5px;
  margin-left: 15px;
  margin-bottom: 0;
  width: calc(100% - 15px);
`;

const SplitShippingContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.background2};
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
  width: calc(100% + 60px);
  transform: translateX(-30px);
  margin-top: -1px;
  padding: 18px 20px 15px 20px;
`;

const SplitShippingDetail = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const SplitShippingImage = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
`;

const SplitShippingDescription = styled.div`
  font-size: 14px;
  margin-left: 15px;
  color: #333;
  p {
    margin: 0;
    a {
      color: #333;
      &:hover {
        color: ${({ theme }) => theme.colors.main};
      }
    }
  }
`;

const Checkbox = styled.div<{ hasError: boolean }>`
  input {
    opacity: 0;
    position: absolute;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    cursor: pointer;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
      ${({ theme, hasError }) =>
        hasError &&
        css`
          border: 2px solid ${theme.colors.error};
        `}
    }
    & > span {
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.04em;
      color: #333;
    }
    a {
      span {
        color: ${({ theme }) => theme.colors.main};
        text-decoration: none;
        &:hover {
          cursor: pointer;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
`;

const Back = styled.div`
  text-align: center;
  text-decoration: underline;
  font-weight: 600;
  letter-spacing: 0.08em;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main};
  &:hover {
    cursor: pointer;
  }
  margin-top: 10px;
`;

const LoadingMaskContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const LoadingMask = styled.div`
  flex: 1;
  height: 7px;
  border-radius: 4px;
  background-color: rgba(151, 123, 43, 0.2);
`;

const LoadingBar = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => percentage}%;
  max-width: 100%;
  height: 7px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.main};
  box-shadow: 0px 0px 10px 0px rgba(151, 123, 43, 1);
`;

const TargetPrice = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};
  padding-left: 20px;
`;

const StyledInputCheckbox = styled(InputCheckbox)`
  padding-top: 10px;
  padding-bottom: 0;
  label {
    span {
      font-size: 13px;
    }
  }
`;

const StyledInputTextArea = styled(InputTextArea)`
  margin-bottom: 0;
  margin-top: 10px;
`;

const CheckoutButton = styled(ButtonLink)`
  margin: 20px 0;
  @media (max-width: 500px) {
    margin: 10px 0;
  }
`;

const Note = styled.div`
  color: #706f6f;
  font-size: 12px;
  letter-spacing: 0.06em;
`;

const CartUpsellWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background2};
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-top: 20px;
  padding: 30px;
  overflow: hidden;
  position: relative;
  @media (max-width: 500px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 30px 20px;
  }
`;

const CartUpsellTotalContainer = styled.div`
  text-align: right;
  padding-top: 15px;
`;

const CartUpsellHeading = styled.div`
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.2em;
  padding-right: 35px;
  width: 100%;
  padding-bottom: 20px;
  color: #977b2b;
`;

const CartUpsellPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.08em;
`;

const CartUpsellOriginalPrice = styled.div`
  text-decoration: line-through;
  padding-right: 5px;
  font-size: 14px;
  color: #333;
  text-align: right;
`;

const CartUpsellTotal = styled.div<{ isDiscounted: boolean }>`
  font-weight: 500;
  font-size: 16px;
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
  font-weight: 700;
`;

const CartUpsellImageContainer = styled.div`
  width: 100px;
  @media (max-width: 500px) {
    width: 80px;
  }
`;

const CartUpsellImageLink = styled(Link)`
  display: block;
`;

const CartUpsellImage = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  padding-bottom: 10px;
`;

const CartUpsellItemLink = styled(Link)`
  font-weight: 500;
  letter-spacing: 0.04em;
  padding-bottom: 5px;
  text-decoration: none;
  color: #333;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

const CartUpsellTitle = styled.div`
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.3em;
  padding-right: 35px;
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const CartUpsellDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
  min-width: calc(100% - 120px);
  padding: 15px 0 0 15px;
  padding-right: 0;
  box-sizing: border-box;
  position: relative;
  flex: 1;
  @media (max-width: 500px) {
    width: calc(100% - 80px);
    padding: 5px 0 0 10px;
    min-width: calc(100% - 80px);
  }
`;

const CartUpsellContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-wrap: wrap;
`;

const CartUpsellAddToCartContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
`;

const CartUpsellAddToCart = styled.button<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isLoading }) =>
    isLoading ? "#806724" : theme.colors.main};
  color: white;
  outline: inherit;

  height: 32px;
  font-size: 14px;
  letter-spacing: 0.16em;
  font-weight: 600;
  border: none;
  text-transform: uppercase;
  font-family: soleil, sans-serif;
  transition: 0.3s all;
  padding: 0 40px;
  min-width: 160px;
  @media (max-width: 500px) {
    padding: 0 20px;
    font-size: 13px;
  }
  &:hover {
    cursor: pointer;
    background-color: #806724;
  }
  &[disabled] {
    background-color: #a3acaf;
    opacity: 0.65;
  }
`;
