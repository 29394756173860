import React from "react";
import styled from "styled-components";
import { LineItem } from "hooks/useCart";
import { mergeBundleLineItems, getBundleId } from "utils/cart";
import DefaultSummaryLine from "./DefaultSummaryLine";
import ComposableSummaryLine from "./ComposableSummaryLine";

interface Props {
  items: LineItem[];
}

function SummaryLineItems({ items }: Props) {
  const cartLineItems = mergeBundleLineItems(items);
  return (
    <Container>
      {cartLineItems.map(cartLineItem => {
        switch (cartLineItem.type) {
          case "composable":
            const bundleId = getBundleId(cartLineItem.base);
            return <ComposableSummaryLine key={bundleId} item={cartLineItem} />;
          case "default":
            return (
              <DefaultSummaryLine
                key={cartLineItem.item.id}
                item={cartLineItem.item}
              />
            );
          default:
            return assertNever(cartLineItem);
        }
      })}
    </Container>
  );
}

function assertNever(x: never) {
  console.error(`Unexpected cart line item`, x);
}

const Container = styled.div`
  overflow: scroll;
`;

export default SummaryLineItems;
