import React, { useRef } from "react";
import useComponentSize from "hooks/useComponentSize";
import styled from "styled-components";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider
} from "react-carousel";
import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import useProductOptions from "hooks/useProductOptions";
import { useLocalizedValue } from "hooks/useLocale";
import Cross from "components/icons/Cross";
import formatMoney from "utils/common/formatMoney";
import {
  getPrice,
  getCompareAtPrice,
  ComposableLineItem as IComposableLineItem,
  getDiscount
} from "utils/cart";
import Thumbnail from "components/Thumbnail";

interface Props {
  item: IComposableLineItem;
  onRemove: () => void;
}

export default function ComposableLineItem({ item, onRemove }: Props) {
  const productOptions = useProductOptions();
  const baseVariant = item.base.variant!;
  const baseTitle = useLocalizedValue(item.base.allLocaleValues.titles);
  let baseImageSrc = "#";
  let baseImageAlt = baseTitle || undefined;
  if (baseVariant.image) {
    baseImageSrc = baseVariant.image.originalSrc;
    if (baseVariant.image.altText) {
      baseImageAlt = baseVariant.image.altText;
    }
  }
  const selectedOptions = baseVariant.selectedOptions
    ? baseVariant.selectedOptions.filter(
        option => option.name !== "Title" && option.value !== "Default Title"
      )
    : [];
  const bundlePrice = getPrice(item);
  const bundleCompareAtPrice = getCompareAtPrice(item);
  const bundleDiscount = getDiscount(item);
  const isDiscounted = bundleCompareAtPrice > bundlePrice - bundleDiscount;
  const ref = useRef(null);
  const size = useComponentSize(ref);
  const visibleSlides =
    size.width < 160
      ? 2
      : size.width < 260
      ? 3
      : size.width < 360
      ? 4
      : size.width < 460
      ? 5
      : size.width < 560
      ? 6
      : 7;
  return (
    <Container>
      <InnerContainer>
        <Base>
          <Thumbnail source={baseImageSrc} alt={baseImageAlt} />
          <Details>
            <Title>{baseTitle}</Title>
            {selectedOptions.map(option => {
              const localizedOption = productOptions[option.name];
              const localizedValue = localizedOption.values.find(
                value => value.shopifyValue === option.value
              );
              return (
                <OptionContainer key={`${option.name}/${option.value}`}>
                  <OptionName>{localizedOption.optionName}:</OptionName>
                  <OptionValue>
                    {localizedValue ? localizedValue.value : option.value}
                  </OptionValue>
                </OptionContainer>
              );
            })}
            <Instants ref={ref}>
              <CarouselProvider
                totalSlides={item.instants.length}
                visibleSlides={visibleSlides}
              >
                <Slider>
                  {item.instants.map((instant, index) => (
                    <Slide key={instant.id} index={index}>
                      <Instant>
                        <InstantThumbnail
                          source={
                            instant.variant!.image
                              ? instant.variant!.image.originalSrc
                              : undefined
                          }
                        />
                      </Instant>
                    </Slide>
                  ))}
                </Slider>
                <ButtonBack>
                  <CircleArrowLeft />
                </ButtonBack>
                <ButtonNext>
                  <CircleArrowRight />
                </ButtonNext>
              </CarouselProvider>
            </Instants>
          </Details>
        </Base>

        <Pricing>
          {isDiscounted && (
            <CompareAtPrice>
              {formatMoney(bundleCompareAtPrice * 100, "€{{amount}}")}
            </CompareAtPrice>
          )}
          <Price isDiscounted={isDiscounted}>
            {formatMoney((bundlePrice - bundleDiscount) * 100, "€{{amount}}")}
          </Price>
        </Pricing>
        <Remove onClick={onRemove}>
          <Cross size="18px" />
        </Remove>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  &:last-of-type {
    border: none;
    padding-bottom: 0;
  }
  @media (max-width: 500px) {
    margin: 8px 0;
    padding-bottom: 10px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;
  font-size: 14px;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Base = styled.div`
  flex: 0 0 85%;
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
  min-width: calc(100% - 120px);
  padding: 15px 0 0 15px;
  padding-right: 0;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 500px) {
    width: calc(100% - 80px);
    padding: 5px 0 0 10px;
    min-width: calc(100% - 80px);
  }
`;

const Title = styled.div`
  color: #333;
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.3em;
  padding-bottom: 5px;
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: start;
  font-weight: 600;
  color: #706f6f;
  font-size: 12px;
  letter-spacing: 0.06em;
`;

const OptionName = styled.span`
  padding-right: 5px;
`;

const OptionValue = styled.span``;

const Instants = styled.div`
  position: relative;
  margin: 10px 30px;
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const Instant = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 4px;
  transition: 0.3s;
`;

const InstantThumbnail = styled(Thumbnail)`
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  border: 2px solid #e2d1a0;
  transition: 0.3s;
  border-radius: 4px;
`;

const Remove = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: -4px;
  right: 0;
  color: ${({ theme }) => theme.colors.text};
  padding: 4px;
  opacity: 0.6;
  transition: 0.3s all;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const Pricing = styled.div`
  display: flex;
  letter-spacing: 0.06em;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 0 0 15%;
  padding: 0 15px;
  font-size: 14px;

  @media (max-width: 650px) {
    font-size: 18px;
    padding: 0;
    padding-bottom: 10px;
    font-weight: 600;
    text-align: right;
    flex: 0 0 100%;
    width: 100%;
    display: inline-block;
  }
`;

const Price = styled.span<{ isDiscounted: boolean }>`
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
`;

const CompareAtPrice = styled.span`
  text-decoration: line-through;
  padding-right: 5px;
  font-size: 14px;
  color: #333;
  text-align: right;
`;
